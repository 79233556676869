<template>
	<div>
		<!-- <keep-alive> -->
		<component :is="getThemeMode.layout" />
		<!-- </keep-alive> -->
	</div>
</template>

<script>
	import { mapGetters } from "vuex"

	export default {
		components: {},
		data() {
			return {}
		},
		computed: {
			...mapGetters({
				getThemeMode: "CONFIG/getThemeMode",
			}),
		},
		methods: {},
	}
</script>